export const isOnline = async (): Promise<boolean | null> => {
  // On the server, we assume that we are online
  return typeof navigator !== 'undefined' ? navigator.onLine : true
}

export const refreshOnline = async () => {}

// subscribe to online/offline events
export const onOnline = (fn: (isOnline: boolean | null) => void) => {
  const online = () => fn(true)
  const offline = () => fn(false)

  if (typeof window === 'undefined') return

  window.addEventListener('online', online)
  window.addEventListener('offline', offline)

  return () => {
    window.removeEventListener('online', online)
    window.removeEventListener('offline', offline)
  }
}
