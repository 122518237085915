type MaybePromise<T> = T | Promise<T>

export type SnekQueryContext = {
  headers: Record<string, string | string[] | undefined>
}

export interface MiddlewareFn {
  (args: {context: SnekQueryContext}): MaybePromise<SnekQueryContext | void>
}

export const processMiddlewares = async (
  middlewares: MiddlewareFn[]
): Promise<SnekQueryContext> => {
  // loop through middlewares and call them with the previous merged context

  let context: SnekQueryContext = {
    headers: {}
  }

  for (const middleware of middlewares) {
    const nextContext = await middleware({context})

    if (nextContext) {
      context = nextContext
    }
  }

  return context
}
