export const isProxied = (target: any): boolean => {
  if (!target) {
    return false
  }

  if (typeof target === 'function' && target.isProxied) {
    return true
  }

  if (Array.isArray(target) && target.length && target[0].$isTracked) {
    return true
  }

  if (target.$isTracked) {
    return true
  }

  return false
}
