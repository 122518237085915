import {TrackedObject} from '../proxy/create-proxy-object.js'
import {graphqlGenerator} from './graphql-generator.js'

// @ts-ignore
import {isOnline, refreshOnline, storage} from 'snek-query'

export interface GraphQLError {
  message: string
  locations?: Array<{line: number; column: number}>
  path?: Array<string | number>
  extensions?: Record<string, any>
}

export class TemporaryExecutor {
  url: string
  headers: Record<string, string | string[] | undefined>
  bypassCache: boolean

  constructor(url: string, headers: TemporaryExecutor['headers'] = {}) {
    this.url = url
    this.headers = headers

    const offlineMode =
      process.env.SQ_OFFLINE_MODE ||
      process.env.EXPO_PUBLIC_SQ_OFFLINE_MODE ||
      process.env.GATSBY_SQ_OFFLINE_MODE ||
      'true'

    this.bypassCache = offlineMode === 'false'
  }

  private isQueryOperation(query: string): boolean {
    // Check if the GraphQL query is a query operation
    return query.trim().startsWith('query ')
  }

  async execute<T>(props: {
    node: T extends TrackedObject ? T : never
    type: 'query' | 'mutation'
    name: string
  }) {
    const query = graphqlGenerator({
      type: props.type,
      name: props.name,
      node: props.node
    })

    const queryStr = query.toString()

    const isQuery = this.isQueryOperation(queryStr)

    if (this.bypassCache) {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this.headers
        },
        body: JSON.stringify({query: queryStr}),
        credentials: 'include'
      })

      const data = (await response.json()) as {
        data: T | null
        errors?: GraphQLError[]
      }

      return data
    }

    const online = await isOnline()

    if (online) {
      const REQUEST_TIMEOUT = 5000
      const HEALTH_TIMEOUT = 2000

      const controller = new AbortController()

      const timeoutId = setTimeout(async () => {
        const healthController = new AbortController()
        const healthTimeoutId = setTimeout(() => {
          healthController.abort()
          controller.abort()
        }, HEALTH_TIMEOUT)

        await refreshOnline()

        try {
          const response = await fetch(this.url, {
            signal: healthController.signal
          })

          if (response.status !== 200) {
            controller.abort()
          }
        } catch (error) {
        } finally {
          clearTimeout(healthTimeoutId)
        }
      }, REQUEST_TIMEOUT)

      try {
        const response = await fetch(this.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...this.headers
          },
          body: JSON.stringify({query: queryStr}),
          credentials: 'include',
          signal: controller.signal
        })

        const data = (await response.json()) as {
          data: T | null
          errors?: GraphQLError[]
        }

        // Check if it's a query operation before caching
        if (isQuery) {
          // Cache the fetched data for queries
          this.cacheData(queryStr, data.data)
        }

        return data
      } catch (error) {
        const cachedData = await this.getCachedData<T>(queryStr)

        if (cachedData) {
          return {
            data: cachedData,
            errors: []
          }
        } else {
          throw error
        }
      } finally {
        clearTimeout(timeoutId)
      }
    } else {
      // If the application is offline, try to retrieve data from the cache
      const cachedData = await this.getCachedData<T>(queryStr)

      if (cachedData) {
        return {
          data: cachedData,
          errors: []
        }
      }

      // Handle the absence of cached data as needed when offline
      throw new Error(
        `snek-query is offline. ${
          isQuery
            ? 'There is no cached data for this query.'
            : 'Mutations cannot be performed while offline.'
        }`
      )
    }
  }

  private async cacheData(query: string, data: any): Promise<void> {
    const cacheKey = this.generateCacheKey(query)
    const cachedData = JSON.stringify(data)
    await storage.set(cacheKey, cachedData)
  }

  private async getCachedData<T>(query: string): Promise<T | null> {
    const cacheKey = this.generateCacheKey(query)
    const cachedData = await storage.get(cacheKey)

    if (cachedData) {
      return JSON.parse(cachedData)
    }

    return null
  }

  private generateCacheKey(query: string): string {
    // Generate a unique cache key based on the query
    return query.trim()
  }
}
