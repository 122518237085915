import {Field, nodePathAnalyzer} from '../node-path-analyzer.js'
import {TrackedObject} from '../proxy/create-proxy-object.js'
import {QueryStructure} from './query-structure.js'

// let observable = new Observable(observer => {
//   setTimeout(async () => {
//     for (let i = 0; i < 10; i++) {
//       await new Promise(resolve => setTimeout(resolve, 1000))

//       observer.next(i)
//     }
//   }, 5000)
//   console.log('Observables invoked!')
// })

// observable.subscribe(v => {
//   console.log(v)

//   fs.writeFileSync('test.txt', v.toString())
// })

export const graphqlGenerator = (props: {
  type: 'query' | 'mutation'
  name: string
  node: TrackedObject
}) => {
  const paths: {
    name: string
    path: string
    args: Field['args']
  }[] = []

  const newNode = props.node

  nodePathAnalyzer({
    node: newNode,
    onPath: (name, path, args) => {
      paths.push({name, path, args})
    }
  })

  const query = new QueryStructure({
    type: props.type,
    name: props.name
  })

  for (const {name, path, args} of paths) {
    query.addField(
      {
        name,
        args
      },
      path
    )
  }

  return query
}
