import {TrackedObject} from './proxy'

export interface NodePopulateProps<T> {
  node: T
  data: Object | null
}

export const nodePopulate = <T extends TrackedObject>(
  props: NodePopulateProps<T>
): T => {
  const {node, data} = props

  const dataLoop = (n: TrackedObject, data: object | null) => {
    const root: any = {}

    if (!data) return null

    for (const [key, dataLvl] of Object.entries(data)) {
      const node = n[key]

      if (node === undefined) {
        continue
      }

      const isFn = typeof node === 'function'

      let value = node

      if (isFn) {
        value = node()
      }

      if (Array.isArray(value) && dataLvl) {
        const data = dataLvl.map((d: object, index: number) => {
          if (value[index]) {
            const o = value[index]
            const data = dataLoop(o, d)

            return data
          } else {
            return d
          }
        })

        value = data
      } else if (value !== null && typeof value === 'object') {
        const data = dataLoop(value, dataLvl)

        value = data
      } else {
        value = dataLvl
      }

      if (isFn) {
        root[key] = () => value
      } else {
        root[key] = value
      }
    }

    return root
  }

  return dataLoop(node, data) as T
}
