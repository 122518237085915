import {Storage} from './index.js'

const localStorage =
  typeof window !== 'undefined'
    ? window.localStorage
    : {
        getItem: () => null,
        setItem: () => {},
        removeItem: () => {}
      }

export const storage = new Storage({
  get: key => Promise.resolve(localStorage.getItem(key)),
  set: (key, value) => Promise.resolve(localStorage.setItem(key, value)),
  remove: key => Promise.resolve(localStorage.removeItem(key))
})
