import type {Field} from './graphql/query-structure'
import {TrackedObject} from './proxy/create-proxy-object'
import {isProxied} from './proxy/is-proxied'

export {Field}

export interface NodePathAnalyzerProps {
  node: TrackedObject
  onPath: (name: string, path: string, args: Field['args']) => void
}

export const nodePathAnalyzer = ({node, onPath}: NodePathAnalyzerProps) => {
  /**
   * Recursively loops through the properties of a TrackedObject and calls the
   * onPath callback with information about each property.
   *
   * @param currentNode The TrackedObject to loop through.
   * @param currentPath The path to the current TrackedObject, represented as an array of property names.
   */
  const loop = (currentNode: TrackedObject, currentPath: string[] = []) => {
    // Mark the current node as untracked, so that we can track which properties are accessed by the onPath callback.
    // currentNode.$isTracked = false

    // Get the tracked properties of the current node.
    const trackedProperties = Object.entries(currentNode.$trackedProps || {})

    if (trackedProperties.length === 0) {
      // If there are no tracked properties, include all properties from the node.
      const properties = Object.entries(currentNode)

      // Call the onPath callback for each non-function property that is not already tracked.
      for (const [name, value] of properties) {
        let v = value

        if (Array.isArray(v)) {
          v = v[0]
        }

        if (typeof v !== 'function' && !isProxied(v) && !name.startsWith('$')) {
          onPath(name, currentPath.join('.'), [])
        }
      }
    } else {
      // If there are tracked properties, loop through each property and process it.
      for (const [name, value] of trackedProperties) {
        let propertyValue: TrackedObject = currentNode[name]
        const isFn = typeof propertyValue === 'function'

        if (isFn && !value.isFnAndCalled) {
          // If the property is a function that has not been called, skip it.
          continue
        }

        if (value.isFnAndCalled) {
          // If the property is a function that has been called, call it and update the property value.
          propertyValue = currentNode[name]()

          // propertyValue.$isTracked = false
        }

        if (Array.isArray(propertyValue)) {
          propertyValue = propertyValue[0]
        }

        // Mark the current node as untracked, so that we can track which properties are accessed by the onPath callback.
        // currentNode.$isTracked = false

        // Call the onPath callback with information about the current property.
        onPath(name, currentPath.join('.'), value.args)

        if (isProxied(propertyValue)) {
          // If the property value is a TrackedObject, recursively loop through its properties.
          const nextPath = [...currentPath, name]
          loop(propertyValue, nextPath)
        }
      }
    }
  }

  // Start the loop with the provided node.
  loop(node)
}
