type GraphQLLikeFnArgs = Record<string, any>

interface TrackedProperty {
  name: string
  args: {name: string; value: any}[]
  isFnAndCalled?: boolean
}
export interface TrackedProperties {
  [key: string]: TrackedProperty
}
export type TrackedObject<T extends Record<string, any> = Record<string, any>> =
  T & {
    $trackedProps: TrackedProperties
    $isTracked: boolean
  }

export function createProxyObject<T extends object>(obj: T): TrackedObject<T> {
  const trackedProperties: TrackedProperties = {}

  const proxy = new Proxy(obj, {
    get(target, propKey: string | symbol, receiver) {
      const propValue = Reflect.get(target, propKey, receiver)

      const isTracked = (target as TrackedObject).$isTracked

      if (
        !isTracked ||
        (typeof propKey === 'string' && propKey.startsWith('$'))
      ) {
        return propValue
      }

      // Only track direct properties
      if (
        typeof propKey === 'string' &&
        !(propKey in trackedProperties) &&
        (obj.hasOwnProperty(propKey) || typeof propValue === 'function')
      ) {
        trackedProperties[propKey] = {
          name: propKey,
          args: []
        }
      }

      // Track function calls
      if (typeof propValue === 'function') {
        return function () {
          const args = (arguments[0] as GraphQLLikeFnArgs) || []

          const trackedArgs = Object.entries(args).map(([key, value]) => {
            return {
              name: key,
              value
            }
          })

          const result = propValue.apply(this, arguments)
          if (typeof propKey === 'string') {
            trackedProperties[propKey]!.isFnAndCalled = true

            // Push args without duplicates (Remove existing args and add new ones)
            for (const arg of trackedArgs) {
              trackedProperties[propKey]!.args = trackedProperties[
                propKey
              ]!.args.filter(a => a.name !== arg.name)

              trackedProperties[propKey]!.args.push(arg)
            }
          }
          return result
        }
      }

      return propValue
    }
  })

  return Object.assign(proxy, {
    $trackedProps: trackedProperties,
    $isTracked: true
  })
}
