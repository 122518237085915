export interface StorageAdapter {
  get: (key: string) => Promise<string | null>
  set: (key: string, value: string) => Promise<void>
  remove: (key: string) => Promise<void>
}

export class Storage {
  storageKey: string

  getAll: () => Promise<Record<string, string> | null>
  get: (key: string) => Promise<string | null>
  set: (key: string, value: string | null) => Promise<void>
  remove: (key: string) => Promise<void>
  removeAll: () => Promise<void>

  constructor(adapter: StorageAdapter, options?: {key?: string}) {
    this.storageKey = options?.key ?? '@snek-query:storage'

    this.getAll = async () => {
      const storedValue = await adapter.get(this.storageKey)
      return storedValue ? JSON.parse(storedValue) : null
    }

    this.get = async (key: string) => {
      // Legacy support (Possible race condition)
      const legacyValue = await adapter.get(this.#makeKey(key))

      if (legacyValue) {
        // Remove legacy key and migrate to new key
        await adapter.remove(this.#makeKey(key))
        await this.set(key, legacyValue)

        return legacyValue
      }

      const storedValue = await this.getAll()

      if (!storedValue) {
        return null
      }

      return storedValue[key] ?? null
    }

    this.set = async (key: string, value: string) => {
      const storedValue = await this.getAll()
      const newValue = JSON.stringify({...storedValue, [key]: value})
      return adapter.set(this.storageKey, newValue)
    }

    this.remove = async (key: string) => {
      return await adapter.remove(key)
    }

    this.removeAll = async () => {
      return await adapter.remove(this.storageKey)
    }
  }

  /**
   * @deprecated
   */
  #makeKey = (key: string) => {
    return `${this.storageKey}:${key}`
  }
}
