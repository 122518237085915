import {GraphQLError} from './graphql/executor.js'
import {proxy} from './proxy/index.js'
import {MiddlewareFn, processMiddlewares} from './snek-query-middleware.js'
import {OnErrorFn} from './snek-query-on-error.js'
import {
  runOperationWithRetry,
  SnekQueryOperation
} from './snek-query-operation.js'

export const makeSnekQuery = <Q extends {}, M extends {}>(
  operations: {
    Query?: new () => Q
    Mutation?: new () => M
  },
  options: {
    apiURL: string
    middlewares?: MiddlewareFn[]
    onError?: OnErrorFn
  }
) => {
  const until = async <O, T>(
    operationType: 'Query' | 'Mutation',
    callback: (data: O) => T,
    operationptions: {
      name?: string
      headers?: Record<string, string | string[] | undefined>
    } = {}
  ) => {
    const operation = operations[operationType]

    if (!operation) throw new Error(`No ${operationType} operation defined.`)

    // override type to prevent $ properties from being added
    const node = proxy<Q | M>(operation)

    callback(node as O)

    // new promise that resolves when the operation is populated
    const promise = new Promise<[T, GraphQLError[] | undefined]>(
      async (resolve, reject) => {
        try {
          const context = await processMiddlewares(options.middlewares || [])

          // merge context headers with operation headers
          context.headers = {
            ...context.headers,
            ...operationptions.headers
          }

          const operation = new SnekQueryOperation({
            apiURL: options.apiURL,
            type: operationType === 'Query' ? 'query' : 'mutation',
            name: operationptions.name || 'SnekQueryOperation',
            node,
            context
          })

          const res = await runOperationWithRetry(operation, options.onError)

          const cbData = callback((res.data as O) || (node as O))
          const cbErrors = res.errors

          resolve([cbData, cbErrors])
        } catch (err) {
          reject(err)
        }
      }
    )

    return promise
  }

  const lazy = <O>(
    operationType: 'Query' | 'Mutation',
    operationName: string = 'Unnamed'
  ): [() => Promise<{data: O; errors?: any[]}>, O] => {
    const operation = operations[operationType]

    if (!operation) throw new Error(`No ${operationType} operation defined.`)

    const node = proxy<Q | M>(operation)

    const getData = async () => {
      const context = await processMiddlewares(options.middlewares || [])

      const operation = new SnekQueryOperation({
        apiURL: options.apiURL,
        type: operationType === 'Query' ? 'query' : 'mutation',
        name: operationName,
        node,
        context
      })

      const res = await runOperationWithRetry(operation, options.onError)

      return {
        data: (res.data as O) || (node as O),
        errors: res.errors
      }
    }

    return [getData, node as O]
  }

  const queryUntil = async <T>(
    callback: (q: Q) => T,
    options: {
      name?: string
      headers?: Record<string, string | string[] | undefined>
    } = {}
  ) => until('Query', callback, options)

  const mutationUntil = async <T>(
    callback: (m: M) => T,
    options: {
      name?: string
      headers?: Record<string, string | string[] | undefined>
    } = {}
  ) => until('Mutation', callback, options)

  const lazyQuery = () => lazy<Q>('Query')
  const lazyMutation = () => lazy<M>('Mutation')

  return {
    query: queryUntil,
    mutate: mutationUntil,
    lazyQuery,
    lazyMutation
  }
}

export type SnekQuery = ReturnType<typeof makeSnekQuery>

export type SnekQueryQueryResult<T extends SnekQuery> = Parameters<
  Parameters<T['query']>[0]
>[0]
export type SnekQueryMutationResult<T extends SnekQuery> = Parameters<
  Parameters<T['mutate']>[0]
>[0]

export type SnekQueryResultFromUntil<
  T extends SnekQuery['query'] | SnekQuery['mutate']
> = Parameters<Parameters<T>[0]>[0]
