import {TrackedObject, createProxyObject} from './create-proxy-object'

/**
 * Returns a proxy object of a class instance that intercepts property access.
 * @param MyCls The class constructor function.
 * @returns A proxy object of the class instance.
 */
export function proxy<T extends object>(MyCls: new () => T): TrackedObject<T> {
  let instance: T | null = null // The instance of the class.
  let proxyObj = createProxyObject({} as T) // The proxy object.

  // Return a proxy object of the class instance.
  return new Proxy({} as TrackedObject<T>, {
    get(_, propKey: string | symbol, receiver) {
      // If the class instance has not been created, create it and wrap it in a proxy object.
      if (!instance) {
        instance = new MyCls()
        proxyObj = createProxyObject(instance)

        // Replace the instance with the proxy object.
        instance = proxyObj as T
      }

      // Return the property value of the class instance.
      return Reflect.get(instance, propKey, receiver)
    },
    getOwnPropertyDescriptor(_, propKey: string | symbol) {
      return Object.getOwnPropertyDescriptor(instance!, propKey)
    },
    ownKeys() {
      return Reflect.ownKeys(instance!)
    }
  })
}

export function arrayProxy<T extends object>(
  MyCls: new () => T
): TrackedObject<T>[] {
  let proxyItem: TrackedObject<T> | undefined
  const items: T[] = []

  const proxy = new Proxy(items, {
    get(target, propKey: string | symbol, receiver) {
      if (!proxyItem) {
        proxyItem = createProxyObject(new MyCls())
      }

      if (
        !proxyItem ||
        !proxyItem.$isTracked ||
        typeof propKey === 'symbol' ||
        propKey === 'length'
      ) {
        return Reflect.get(target, propKey, receiver)
      }

      const index = Number(propKey)
      if (isNaN(index)) {
        const isPrototypeFn = Array.prototype.hasOwnProperty(propKey)
        if (isPrototypeFn) {
          // override prototype call with other base array

          return (...args: any[]) => {
            const prototypeKey = propKey as keyof typeof Array.prototype

            switch (prototypeKey) {
              case 'concat':
              case 'copyWithin':
              case 'fill':
              case 'pop':
              case 'push':
              case 'reverse':
              case 'shift':
              case 'sort':
              case 'splice':
              case 'unshift':
              case 'filter':
                Array.prototype[prototypeKey].call([proxyItem], ...args)
                return [proxyItem]
              case 'entries':
              case 'flatMap':
              case 'forEach':
              case 'keys':
              case 'map':
              case 'slice':
              case 'values':
                return Array.prototype[prototypeKey].call([proxyItem], ...args)
              case 'every':
                return Array.prototype[prototypeKey].call(
                  [proxyItem],
                  ...args
                ) as boolean
              case 'find':
              case 'findIndex':
              case 'includes':
              case 'indexOf':
              case 'lastIndexOf':
              case 'some':
                return Array.prototype[prototypeKey].call(
                  [proxyItem],
                  ...args
                ) as number | boolean
              case 'flat':
                return Array.prototype[prototypeKey].call(
                  [proxyItem],
                  ...args
                ) as any[] // can return an array of any type
              case 'reduce':
              case 'reduceRight':
                return Array.prototype[prototypeKey].call(
                  [proxyItem],
                  ...args
                ) as any // can return any type
              case 'toLocaleString':
              case 'toString':
                return Array.prototype[prototypeKey].call([proxyItem]) as string
              default:
                throw new Error(
                  `Unsupported array prototype method: ${prototypeKey.toString()}`
                )
            }
          }
        }

        return Reflect.get(target, propKey, receiver)
      }

      return proxyItem
    }
  })

  return proxy as TrackedObject<T>[]
}

export const fnProxy = <T extends {}>(MyCls: new () => T) => {
  const p = proxy(MyCls)

  const fn = () => {
    return p
  }

  fn.isProxied = true

  return fn
}

export const fnArrayProxy = <T extends {}>(MyCls: new () => T) => {
  const p = arrayProxy(MyCls)

  const fn = () => {
    return p
  }

  fn.isProxied = true

  return fn
}
