interface PreventStringSerializationFn {
  (): any
  preventStringSerialization: true
}

export type Field = {
  name: QueryStructure['name']
  args: {
    name: string
    value: any
  }[]
  fields?: Field[]
  isNode?: boolean
}

export class QueryStructure {
  type: 'query' | 'mutation' | 'subscription'
  name: string
  // args: {name: string; type: string}[] = []
  fields: Field[]

  constructor(options: {
    type: QueryStructure['type']
    name: QueryStructure['name']
  }) {
    this.type = options.type
    this.name = options.name

    this.fields = []
  }

  addField(field: Field, path: string = '') {
    field.args = field.args.filter(arg => {
      // when string or object and not null or undefined

      const argType = typeof arg.value

      const isValid =
        (argType === 'string' ||
          argType === 'number' ||
          argType === 'boolean' ||
          argType === 'object' ||
          (argType === 'function' && arg.value.preventStringSerialization)) &&
        arg.value !== null &&
        arg.value !== undefined

      return isValid
    })

    if (path) {
      const pathParts = path.split('.')

      let currentPath = this.fields

      for (const pathPart of pathParts) {
        const field = currentPath.find(field => field.name === pathPart)

        if (field) {
          field.fields = field.fields || []

          currentPath = field.fields
        }
      }

      currentPath.push(field)
    } else {
      this.fields.push(field)
    }
  }

  toString() {
    const buildFieldString = (field: Field): string => {
      const argString = field.args
        .map(arg => {
          const valueString =
            typeof arg.value === 'function' &&
            arg.value.preventStringSerialization
              ? stringifyArgs(arg.value(), false)
              : stringifyArgs(arg.value)

          return `${arg.name}: ${valueString}`
        })
        .join(', ')

      const fieldString = field.fields
        ? ` {__typename ${buildFields(field.fields)}}`
        : ''

      return argString
        ? `${field.name}(${argString})${fieldString}`
        : `${field.name}${fieldString}`
    }

    const buildFields = (fields: Field[]): string => {
      return fields.map(buildFieldString).join(' ')
    }

    return `${this.type} ${this.name} {__typename ${buildFields(this.fields)}}`
  }
}

export function doNotConvertToString(data: any): PreventStringSerializationFn {
  const fn: PreventStringSerializationFn = () => {
    return data
  }

  fn.preventStringSerialization = true

  return fn
}

type Enum = {
  [key: string]: any
}

export const asEnumKey = <T extends Enum>(e: T | null, key: keyof T) => {
  if (e === null) {
    return key as unknown as T[keyof T]
  }

  if (e[key] === undefined) {
    throw new Error(`Enum key does not exist in enum: ${key.toString()}`)
  }

  return doNotConvertToString(key) as unknown as T[keyof T]
}

function stringifyArgs(data: any, stringifyValue: boolean = true): string {
  if (data === null || data === undefined) {
    return 'null'
  }

  if (typeof data === 'function' && data.preventStringSerialization) {
    const value = data()
    return stringifyArgs(value, false)
  }

  if (typeof data !== 'object') {
    return stringifyValue ? JSON.stringify(data) : String(data)
  }

  if (Array.isArray(data)) {
    const items = data
      .map(item => stringifyArgs(item, stringifyValue))
      .join(', ')
    return stringifyValue ? `[${items}]` : items
  }

  if (typeof data === 'object') {
    const props = Object.entries(data)
      .map(([key, value]) => `${key}: ${stringifyArgs(value, stringifyValue)}`)
      .join(', ')
    return `{${props}}`
  }

  throw new Error(`Cannot stringify data of type ${typeof data}`)
}
