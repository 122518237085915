import {TemporaryExecutor} from './graphql/executor.js'
import {nodePopulate} from './node-populate.js'
import {TrackedObject} from './proxy/create-proxy-object.js'
import {SnekQueryContext} from './snek-query-middleware.js'
import {OnErrorFn} from './snek-query-on-error.js'

export class SnekQueryOperation {
  #type: 'query' | 'mutation'
  name: string
  #node: TrackedObject
  #context: SnekQueryContext
  #apiURL: string
  #attempt = 0

  constructor(args: {
    apiURL: string
    type: 'query' | 'mutation'
    name: string
    node: TrackedObject
    context: SnekQueryContext
  }) {
    this.#type = args.type
    this.name = args.name
    this.#node = args.node
    this.#context = args.context
    this.#apiURL = args.apiURL
  }

  getContext() {
    return this.#context
  }

  setContext(context: SnekQueryContext) {
    this.#context = context
  }

  getAttempt() {
    return this.#attempt
  }

  async execute() {
    this.#attempt++

    const executor = new TemporaryExecutor(this.#apiURL, this.#context.headers)

    const {data, errors} = await executor.execute({
      type: this.#type,
      name: this.name,
      node: this.#node
    })

    const populated = data
      ? nodePopulate({
          node: this.#node,
          data
        })
      : null

    return {
      data: populated,
      errors
    }
  }
}

type ValueType<T> = T extends Promise<infer U> ? U : T

export const runOperationWithRetry = async (
  operation: SnekQueryOperation,
  onError?: OnErrorFn
) => {
  let operationResult: ValueType<ReturnType<typeof operation.execute>> = {
    data: null,
    errors: []
  }
  let networkError

  try {
    operationResult = await operation.execute()
  } catch (e) {
    networkError = e
  }

  if (onError && (operationResult?.errors || networkError)) {
    const forwarded = onError?.({
      forward: op => runOperationWithRetry(op),
      operation,
      graphQLErrors: operationResult?.errors || [],
      networkError
    }) as Promise<typeof operationResult> | undefined

    if (forwarded) {
      const forwardedResult = await forwarded

      if (forwardedResult) {
        operationResult = forwardedResult

        return operationResult
      }
    }
  }

  if (networkError) {
    throw networkError
  }

  return operationResult
}
